import React from "react"
import Layout from "../components/Layout"
// import Banner from "../components/Banner"
import StyledHero from "../components/StyledHero"
import { graphql } from "gatsby"
import SEO from "../components/SEO"
import "../css/prayer.css"
export default ({ data }) => (
  <Layout>
    <SEO title="Prayer" />
    <StyledHero home="true" img={data.images1.childImageSharp.fluid}>
      <div className="Prayer1G">G</div>
      <div className="Prayer1Gs">od grant me</div>
      <div className="Prayer1">
        the <span style={{ color: "#FAF35E" }}>Serenity</span> to accept the
        things I cannot change;
      </div>
      <div className="Prayer2">
        <p>
          the <span style={{ color: "#FAF35E" }}>Courage</span> to change the
          things I can;
        </p>
      </div>
      <div className="Prayer3">
        and the <span style={{ color: "#FAF35E" }}>Wisdom</span> to know the
        difference.
      </div>
    </StyledHero>
  </Layout>
)
export const query = graphql`
  query {
    images1: file(relativePath: { eq: "serenity-hero1.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
